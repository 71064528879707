/*
Template Name: Minton - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

@import 'bootstrap';
@import 'icons';
@import 'app';

.react-calendar {
    border: none!important;
    margin: auto;
    .react-calendar__tile{
        border-radius: 5px;
    }
    .react-calendar__tile--now{
        background-color: #ddd;
        &:hover{
            background: #eee!important;
        }
    }
}

@media screen and (max-width: 992px){
    .scroll-container{
        overflow: auto;
    }
    
    .scroll-nav{
        min-width: 90vw;
    }
}